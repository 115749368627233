//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
//获取房号列表
const getRoomListUrl = `/gateway/hc-space/space/query`;
//获取房号审核列表
const getRoomHandleListUrl = `/gateway/hc-space/space/getUserSpaceRelationList`;
//审核房号
const handleRoomUrl = `/gateway/hc-space/space/examineUserSpaceRelation`;
//审核通过房号
const passRoomUrl = `/gateway/hc-space/space/examineUserSpaceRelation`;
//驳回房号
const rejectRoomUrl = `/gateway/hc-space/space/examineUserSpaceRelation`;
//获取房号
const getLinkUrl = `/gateway/hc-space/space/link`;
export {
  getCommunityListUrl,
  getRoomHandleListUrl,
  handleRoomUrl,
  passRoomUrl,
  rejectRoomUrl,
  getLinkUrl,
  getRoomListUrl,
};
