<template>
  <div class="roomManager">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :selectable="selectable"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <area-select
          @changeModel="changeModel"
          specifyCollectionTypes="1"
          :disabled="true"
        />
        <!-- <community-select v-model="searchParam.spaceId" label="选择园区" />
        <v-input label="幢" v-model="searchParam.buildingNum" type="number" />
        <v-input label="单元" v-model="searchParam.unitNum" type="number" />
        <v-input label="室" v-model="searchParam.roomNum" type="number" /> -->
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="toEdit(scope.row.id)"
        />
      </template>
      <!-- <template #batchSlot="scope">
        <v-button
          text="批量审核"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        ></v-button>
      </template> -->
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="驳回"
      sureTxt="通过"
    >
      <span>是否审核通过?</span>
    </v-dialog>
  </div>
</template>

<script>
import roomLevel from "@/components/bussiness/roomLevel/roomLevel.vue";
import { getRoomListUrl, passRoomUrl } from "./api.js";
import {
  auditStatusOptions,
  auditStatusMap,
  relationOptions,
  relationOptionsMap,
  sexOptions,
  sexOptionsMap,
  userTypeOptionsMap,
  userTypeOptions,
} from "./map";

export default {
  name: "roomManager",
  data() {
    return {
      isdialog: false,
      id: "",
      relationOptions,
      sexOptions,
      userTypeOptions,
      auditStatusOptions,
      searchParam: {
        spaceIds: null,
        // unitNum: "",
        // buildingNum: "",
        // roomNum: "",
      },
      extraParams: {},
      tableUrl: getRoomListUrl,
      headers: [
        {
          prop: "communityName",
          label: "小区",
        },
        {
          prop: "buildingName",
          label: "楼幢",
        },
        {
          prop: "unitName",
          label: "单元",
        },

        {
          prop: "roomName",
          label: "房号",
        },

        {
          prop: "path",
          label: "地址",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    changeModel(value) {
      this.searchParam.spaceId = value.spaceId;
    },
    selectable(row) {
      if (row.auditStatus != 0) {
        return false;
      }
      return true;
    },
    cancel() {
      this.handleRoomUrl(this.id, 2);
    },
    confirm() {
      this.isdialog = false;
      this.handleRoomUrl(this.id, 1);
    },
    toEdit(id) {
      this.isdialog = true;
      this.id = id;
    },
    handleRoomUrl(ids, auditStatus) {
      let params = {
        ids: ids,
        auditStatus: auditStatus,
        auditUser: this.$store.state.app.userInfo.id,
      };
      this.$axios
        .post(`${passRoomUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
    },
    batchLineSend(row, type) {
      let id = row.id;
      this.send(id, type);
    },
    batchSend(list, type) {
      console.log("list--", list);
      const { data, ids } = list;
      let id = ids.join(",");
      //   this.send(id, type);
      this.toEdit(id);
    },
  },
};
</script>
<style lang="less" scoped>
.roomManager {
  box-sizing: border-box;
  height: 100%;
}
</style>
