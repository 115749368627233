import { mapHelper } from "@/utils/common";

const auditStatusOptions = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
];
const { map: auditStatusMap, setOps: auditStatusOps } =
  mapHelper.setMap(auditStatusOptions);
const relationOptions = [
  { value: 0, label: "产权人本人" },
  { value: 1, label: "产权人配偶" },
  { value: 2, label: "家属" },
  { value: 3, label: "租客" },
  { value: 4, label: "访客" },
  { value: 5, label: "上门服务人员" },
];

const { map: relationOptionsMap, setOps: relationOptionsOps } =
  mapHelper.setMap(relationOptions);

const sexOptions = [
  {
    value: "F",
    label: "女",
  },
  {
    value: "M",
    label: "男",
  },
];

const { map: sexOptionsMap, setOps: sexOptionsOps } =
  mapHelper.setMap(sexOptions);

const userTypeOptions = [
  {
    value: "10",
    label: "居民",
  },
  {
    value: "11",
    label: "商户",
  },
  {
    value: "21",
    label: "运营人员",
  },
];
const { map: userTypeOptionsMap, setOps: userTypeOptionsOps } =
  mapHelper.setMap(userTypeOptions);
const areaOptions = [
  {
    value: "10",
    label: "小区",
  },
  {
    value: "11",
    label: "区域",
  },
  {
    value: "21",
    label: "机构类型",
  },
];
const { map: areaOptionsMap, setOps: areaOptionsOps } =
  mapHelper.setMap(areaOptions);
export {
  auditStatusOptions,
  auditStatusMap,
  relationOptions,
  relationOptionsMap,
  sexOptions,
  sexOptionsMap,
  userTypeOptionsMap,
  userTypeOptions,
  areaOptionsMap,
  areaOptions,
};
